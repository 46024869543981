<template>
  <div :class="$style.add">
    <div id="box" :class="$style.type">
      <p :class="$style.title">预订类型</p>
      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          预订类型
        </div>
        <div class="value line-40">
          <a-radio-group v-model="data.type">
            <a-radio :value="1"> 门票预订 </a-radio>
            <a-radio :value="2"> 场地预订 </a-radio>
            <a-radio :value="3"> 自定义类型 </a-radio>
          </a-radio-group>
          <div v-if="data.type === 3">
            <BjInput v-model="data.type_name" placeholder="请设置预订类型" />
          </div>
          <p class="tip mb-0">预订类型用于标识预订的用途，前端会以预订类型分组显示。</p>
        </div>
      </div>
    </div>

    <div :class="$style.info">
      <p :class="$style.title">预订信息</p>
      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          预订条目
        </div>
        <div class="value">
          <div v-for="(item, index) in data.items" :key="index" :class="$style.box">
            <div :class="$style.head">
              <span :class="$style.four">条目名称</span>
              {{ item.name }}
              <div :class="$style.btn">
                <i class="ri-edit-line primary" @click="onEdit(item, index)" />
                <i class="ri-delete-bin-7-line" @click="onDelete(index)" />
              </div>
            </div>
            <div :class="$style.text">
              <p>
                <span :class="$style.four">条目说明</span>
                {{ item.value }}
              </p>
              <p>
                <span :class="$style.four">预订须知</span>
                {{ item.describe }}
              </p>
            </div>
          </div>
          <BjButton type="dashed" class="mt-10" @click="onAddItem()">
            <i class="ri-add-line left" />
            添加条目
          </BjButton>
        </div>
      </div>

      <div class="layout mt-20">
        <div class="label">预订规格</div>
        <div class="value">
          <div v-for="(item, index) in data.specs" :key="index" :class="$style.box">
            <div :class="$style.head">
              <span :class="$style.four">规格名称</span>
              {{ item.name }}
              <div :class="$style.btn">
                <i class="ri-edit-line primary" @click="onEdits(item, index)" />
                <i class="ri-delete-bin-7-line" @click="onDeletes(index)" />
              </div>
            </div>
            <div :class="$style.text">
              <p v-for="(citem, cindex) in item.values" :key="cindex">
                <span :class="$style.four">规格值</span>
                {{ citem.value }}
              </p>
            </div>
          </div>
          <BjButton type="dashed" class="mt-10" @click="onAddSpecs()">
            <i class="ri-add-line left" />
            添加规格
          </BjButton>
        </div>
      </div>

      <div class="layout mt-20">
        <div class="label">
          <span class="required">*</span>
          规格明细
        </div>
        <div class="value">
          <a-table :columns="columns" :data-source="data.skus" :pagination="false" row-key="id">
            <template #count="item">
              <BjInput v-model="item.count" suffix="份" @keyup="item.count = item.count.replace(/[^\d\.-]/g, '')" />
            </template>
            <template #price="item">
              <BjInput v-model="item.price" suffix="元" @keyup="item.price = item.price.replace(/[^\d\.-]/g, '')" />
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div :class="$style.set">
      <p :class="$style.title">预订配置</p>
      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          预订周期
        </div>
        <div class="value line-40">
          <bj-input v-model="data.cycle" placeholder="请输入数字" addon-before="用户可预订" addon-after="天内的条目" />
        </div>
      </div>

      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          不可预订
        </div>
        <div class="value">
          <a-radio-group v-model="data.close_type" class="mt-8" @change="weekChange">
            <a-radio :value="1"> 按周循环 </a-radio>
            <a-radio :value="2"> 按月循环 </a-radio>
          </a-radio-group>
          <div :class="$style.group">
            <a-checkbox-group v-if="data.close_type === 1" v-model="data.close_time" :options="week" />
            <a-checkbox-group v-if="data.close_type === 2" v-model="data.close_time" :options="month" />
          </div>
          <div class="tip mb-20">勾选后的日期，用户将不能预订此类目。如有特殊需求，请在预订日历中管理。</div>
        </div>
      </div>

      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          预订方式
        </div>
        <div class="value line-40">
          <a-radio-group v-model="data.method">
            <a-radio :value="1"> 预约预订 </a-radio>
            <a-radio :value="2"> 支付预订 </a-radio>
          </a-radio-group>
          <div class="tip mb-20">
            “预约预订”仅需用户提交预订信息后即视为预订成功；“支付预订”需用户提交预订信息且支付款项后视为预订成功。
          </div>
        </div>
      </div>

      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          退订方式
        </div>
        <div class="value line-40">
          <a-radio-group v-model="data.cancel_type">
            <a-radio :value="1"> 随时可退 </a-radio>
            <a-radio :value="2"> 不可退订 </a-radio>
            <a-radio :value="3"> 过期前退 </a-radio>
          </a-radio-group>
          <div class="tip mb-20">请设置预订是否可退订，如果是支付预订，退订将退回支付的费用。</div>
        </div>
      </div>

      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          取票方式
        </div>
        <div class="value line-40">
          <a-radio-group v-model="data.ticket_type">
            <a-radio :value="1"> 需要取票 </a-radio>
            <a-radio :value="2"> 无需取票 </a-radio>
          </a-radio-group>
          <div class="tip mb-20">请设置预订是否需要取票。</div>
        </div>
      </div>
    </div>

    <div :class="$style.footer" :style="{ width: footWidth }">
      <BjButton class="mr-10" type="primary" @click="onSave()">
        <i class="ri-save-line left" />
        立即保存
      </BjButton>
      <BjButton @click="onCannel()"> 取消添加 </BjButton>
    </div>

    <bj-modal :visible="visible.item" :title="title.item" @cancel="cancel" @ok="submit">
      <BjForm :class="$style.antForm">
        <BjInput
          v-model="item.name"
          label="条目名称"
          v-bind="layout"
          label-align="left"
          rules="required"
          placeholder="请输入条目名称"
        />
        <a-row>
          <a-col span="6" />
          <a-col span="18">
            <p class="tip mt-10">
              条目即为需要预订的项目，比如门票预订的条目：成人票、儿童票、老年票等、场馆预订的条目：大堂、包间等，请根据实际情况填写。
            </p>
          </a-col>
        </a-row>
        <BjInput
          v-model="item.value"
          label="条目说明"
          v-bind="layout"
          class="mb-20"
          label-align="left"
          placeholder="请输入条目说明，一句话简单描述"
        />
        <a-row>
          <a-col span="6"> 预订须知 </a-col>
          <a-col span="18">
            <a-textarea v-model="item.describe" placeholder="请输入预订须知" :auto-size="{ minRows: 3, maxRows: 3 }" />
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>

    <bj-modal :visible="visible.specs" :title="title.specs" @cancel="specsCancel" @ok="specsSubmit">
      <BjForm :class="$style.antForm">
        <BjInput
          v-model="specs.name"
          label="规格名称"
          v-bind="layout"
          label-align="left"
          rules="required"
          placeholder="请输入规格名称"
        />
        <a-row>
          <a-col span="6" />
          <a-col span="18">
            <p class="tip mt-10">规格即为条目的SKU，可以设置更多条目相关的属性，比如时间场次、大小规格等。</p>
          </a-col>
        </a-row>
        <a-row class="mb-20">
          <a-col span="6">
            <span class="required">*</span>
            规格值
          </a-col>
          <a-col span="18">
            <div v-for="(item, index) in specs.values" :key="index" :class="$style.inputbox">
              <BjInput v-model="item.value" :class="$style.input" placeholder="请输入规格值" />
              <i class="ri-delete-bin-7-line" @click="onDeleteSpecsValue(index)" />
            </div>
            <BjButton block @click="onAddSpecsValue()">
              <i class="ri-add-line left" />
              添加一条
            </BjButton>
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import _ from 'lodash'

import { appFormServce } from '@/service'

const service = new appFormServce()

export default {
  name: 'serviceAdd',
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      footWidth: '800px',
      data: {
        type: 1,
        type_name: null,
        cycle: null,
        close_type: 1,
        items: [],
        specs: [],
        skus: [],
        method: 1,
        cancel_type: 1,
        ticket_type: 1,
        close_time: [],
      },
      repeatName: '', // 去重编辑规格名称
      week: [
        {
          label: '星期一',
          value: 1,
        },
        {
          label: '星期二',
          value: 2,
        },
        {
          label: '星期三',
          value: 3,
        },
        {
          label: '星期四',
          value: 4,
        },
        {
          label: '星期五',
          value: 5,
        },
        {
          label: '星期六',
          value: 6,
        },
        {
          label: '星期天',
          value: 7,
        },
      ],
      month: [],
      visible: {
        item: false,
        specs: false,
      },
      title: {
        item: '添加条目',
        specs: '添加规格',
      },
      item: {
        name: null,
        value: null,
        describe: null,
      },
      index: {
        item: 0,
        specs: 0,
      },
      specs: {
        name: null,
        values: [],
      },
      columns: [],
      id: null,
      rid: null,
    }
  },
  mounted() {
    this.footWidth = document.getElementById('box').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('box').offsetWidth + 'px'
    }
  },
  created() {
    if (this.$route.query.serve_id) {
      this.id = this.$route.query.serve_id
      this.getInfo()
    }
    this.rid = this.$route.query.id
    for (let index = 1; index < 32; index++) {
      this.month.push({
        label: '每月' + index + '号',
        value: index,
      })
    }
  },
  methods: {
    onEdit(item, index) {
      this.item = _.cloneDeep(item)
      this.index.item = index
      this.title.item = '编辑条目'
      this.visible.item = true
    },
    onDelete(index) {
      this.data.items.splice(index, 1)
      this.getSku()
    },
    onEdits(item, index) {
      this.specs = _.cloneDeep(item)
      this.index.specs = index
      this.title.specs = '编辑规格'
      this.visible.specs = true
      this.repeatName = item.name
    },
    onDeletes(index) {
      this.data.specs.splice(index, 1)
      this.getSku()
    },
    weekChange() {
      this.data.close_time = []
    },
    async getInfo() {
      const { data } = await service.getServe({
        id: this.id,
      })
      this.data = data
      this.getSku(data.skus)
    },
    onAddItem() {
      this.item = {
        name: null,
        value: null,
        describe: null,
      }
      this.title.item = '添加条目'
      this.visible.item = true
    },
    submit() {
      if (!this.item.name) {
        this.$message.error('请输入条目名称')
        return
      }
      if (this.title.item === '添加条目') {
        this.data.items.push(this.item)
      } else {
        this.data.items.splice(this.index.item, 1, this.item)
      }
      this.getSku()
      this.cancel()
    },
    cancel() {
      this.visible.item = false
    },
    onAddSpecs() {
      this.specs = {
        name: null,
        values: [],
      }
      this.title.specs = '添加规格'
      this.visible.specs = true
    },
    onAddSpecsValue() {
      this.specs.values.push({
        value: null,
      })
    },
    onDeleteSpecsValue(index) {
      this.specs.values.splice(index, 1)
    },
    specsCancel() {
      this.visible.specs = false
    },
    specsSubmit() {
      if (!this.specs.name) {
        this.$message.error('请输入规格名称')
        return
      }
      if (!this.specs.values.length) {
        this.$message.error('请输入规格值')
        return
      }
      if (this.specs.values.filter(item => !item.value).length) {
        this.$message.error('请输入规格值')
        return
      }
      // 编辑状态判断和之前一致不
      if (this.title.specs === '添加规格') {
        if (this.data.specs.filter(item => item.name === this.specs.name).length) {
          this.$message.error('规格名称重复')
          return
        }
      } else {
        if (this.data.specs.filter(item => item.name === this.specs.name).length) {
          if (this.repeatName !== this.specs.name) {
            this.$message.error('规格名称重复')
            return
          }
        }
      }
      if (this.specs.name === '预订条目') {
        this.$message.error('规格名称重复')
        return
      }
      // 规格值重复
      let obj2 = {}
      let res2 = []
      res2 = this.specs.values.reduce((prev, item) => {
        obj2[item.value] ? '' : (obj2[item.value] = true && prev.push(item))
        return prev
      }, [])
      if (res2.length !== this.specs.values.length) {
        this.$message.error('规格值重复')
        return
      }
      if (this.title.specs === '添加规格') {
        this.data.specs.push(this.specs)
      } else {
        this.data.specs.splice(this.index.specs, 1, this.specs)
      }
      this.getSku()
      this.specsCancel()
    },
    async getSku(data) {
      if (typeof data === 'undefined') {
        let res = await service.getSku({
          items: this.data.items,
          specs: this.data.specs,
        })
        data = res.data
      }

      this.columns = []
      this.data.skus = []
      if (data.length) {
        this.columns.push({
          title: '预订条目',
          dataIndex: 'item_name',
          //   customRender: (value, row, index) => {
          //     const obj = {
          //       children: value,
          //       attrs: {},
          //     }
          //     if (this.data.specs.length) {
          //     }
          //     return obj
          //   },
        })
        data[0].sku_values.map(item => {
          this.columns.push({
            title: item.spec_name,
            dataIndex: item.spec_name,
          })
        })
        this.columns.push({
          title: '预订数量',
          scopedSlots: {
            customRender: 'count',
          },
        })
        this.columns.push({
          title: '预订价格',
          scopedSlots: {
            customRender: 'price',
          },
        })
        data.map(item => {
          item.sku_values.map(citem => {
            item[citem.spec_name] = citem.spec_value
          })
        })
        this.data.skus = data
      }
    },
    async onSave() {
      let skus = this.data.skus
      skus.map(item => {
        item.price = Number(item.price)
        item.count = Number(item.count)
      })
      let data = {
        venue_id: this.$route.query.id,
        ...this.data,
        cycle: Number(this.data.cycle),
        skus: skus,
      }
      await service.saveService(data)
      this.$message.success('保存成功')
      this.$router.push({
        name: 'appVenueManageService',
        query: {
          id: this.rid,
        },
      })
    },
    onCannel() {
      this.$router.push({
        name: 'appVenueManageService',
        query: {
          id: this.rid,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.inputbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .input {
    flex: 1;
  }

  i {
    margin-left: 20px;
    font-size: 18px;
  }
}

.add {
  .type {
    padding: 20px;
    background: #fff;
  }

  .set {
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 20px;
    background: #fff;

    :global {
      .ant-form-item {
        margin-bottom: 20px;
      }
    }
  }

  .group {
    margin-top: 10px;
    margin-bottom: 10px;
    :global {
      .ant-checkbox-group-item {
        width: 100px;
      }
    }
  }
  .four {
    width: 70px;
    display: inline-block;
  }
  .info {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    width: 880px;
    height: 60px;
    padding: 0 20px;
    line-height: 60px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
  }

  .box {
    border: 1px solid #eee;
    margin-bottom: 10px;

    .head {
      height: 48px;
      padding: 0 13px;
      font-size: 14px;
      line-height: 48px;
      background: #fafafa;
    }

    .text {
      padding: 14px;
      font-size: 14px;

      p {
        margin-bottom: 10px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.antForm {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.btn {
  font-size: 18px;
  float: right;

  i {
    margin-left: 10px;
  }
}
</style>
